import React from "react"

import css from "./footer.css"

export default function Footer() {
  return (
    <footer>
      <div css={css}>
        <span>Arancha Palacio</span>
        <span className={"icon-heart"}></span>
        <span>Pablo García</span>
      </div>
    </footer>
  )
}
