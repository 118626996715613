import { useState, useEffect } from "react"

const useOffset = ({ speed = 0.5, node } = {}) => {
  const [offset, setOffset] = useState(0)

  useEffect(() => {
    function handleScroll() {
      const offsetHeight = node?.offsetHeight || 0
      setOffset(window.pageYOffset - offsetHeight)
    }
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [speed, node])

  return [offset * speed]
}

export default useOffset
