import { graphql, useStaticQuery } from "gatsby"

const useImageData = () => {
  const imageData = useStaticQuery(
    graphql`
      query {
        hero: file(absolutePath: { regex: "/hero-image.jpg/" }) {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 70) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
        rsvpForm: file(absolutePath: { regex: "/form-back.jpg/" }) {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        usParallax: file(absolutePath: { regex: "/us-background.jpg/" }) {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        restaurante: file(absolutePath: { regex: "/restaurante.jpg/" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        peluqueria: file(absolutePath: { regex: "/peluqueria.jpg/" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        hotel: file(absolutePath: { regex: "/hotel.jpg/" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        aviles: file(absolutePath: { regex: "/aviles.jpg/" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        autobus: file(absolutePath: { regex: "/autobus.jpg/" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        menu: file(absolutePath: { regex: "/menu.jpg/" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        paris: file(absolutePath: { regex: "/paris.jpeg/" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        hector: file(absolutePath: { regex: "/hector.jpeg/" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        sergio: file(absolutePath: { regex: "/sergio.jpeg/" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        rodri: file(absolutePath: { regex: "/rodri.jpeg/" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        sara: file(absolutePath: { regex: "/sara.jpeg/" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        irene: file(absolutePath: { regex: "/irene.jpeg/" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        diana: file(absolutePath: { regex: "/diana.jpeg/" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        meri: file(absolutePath: { regex: "/meri.jpeg/" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        wedding: file(absolutePath: { regex: "/wedding.jpg/" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return imageData
}

export default useImageData
