import { css } from "@emotion/core"

export default css`
  @keyframes onOpenAnimation {
    0% {
      transform: translate(100%);
      opacity: 0;
    }

    1% {
      transform: translate(0);
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes onCloseAnimation {
    0% {
      opacity: 1;
      transform: translate(0);
    }

    99% {
      transform: translate(0);
    }

    100% {
      transform: translate(100%);
      opacity: 0;
    }
  }

  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  padding: 1rem 0;
  min-height: 58px;

  span {
    display: none;
    z-index: 1;
    top: 1rem;
    right: 1rem;
    position: absolute;
    font-family: "Great Vibes", cursive;
    transition: color 0.3s, background-color 0.3s;
    line-height: 2.5rem;
    background-color: #c38e9e;
    border: 1px solid white;
    padding: 0.5rem;
    border-radius: 100px;
    font-size: 1.1rem;
    color: white;
    cursor: pointer;
    width: 2.5rem;
    height: 2.5rem;
    &.float {
      top: 7px;
      left: 7px;
      font-size: 1.2rem;
    }
  }

  .openMenu {
    display: none;
    text-align: right;
    color: white;
    font-size: 2.5rem;
    margin-right: 40px;
  }

  ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;

    li {
      :not(:last-child) {
        &:after {
          color: white;
          content: "·";
          vertical-align: middle;
          font-size: 20px;
          margin: 0px 20px;
        }
      }
      a {
        color: white;
        font-size: 1rem;
        text-decoration: none;
        text-transform: uppercase;
      }
    }
  }

  &.negative {
    background-color: white;

    a {
      color: grey;
    }
  }

  @media (max-width: 720px) {
    background-color: transparent;

    &.opened {
      span {
        background-color: white;
        border: 1px solid #c38e9e;
        color: #c38e9e;
      }

      .menu__background {
        opacity: 1;
        transform: translate(0);
        animation-name: onOpenAnimation;
        animation-duration: 0.5s;
      }

      ul {
        opacity: 1;
        transform: translate(0);
      }
    }

    &.closed {
      .menu__background {
        opacity: 0;
        transform: translate(100%);
        animation-name: onCloseAnimation;
        animation-duration: 0.5s;
      }
    }

    span {
      display: flex;
    }

    .menu__background {
      opacity: 0;
      transform: translate(100%);
      background-color: white;
      position: absolute;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
    }

    ul {
      opacity: 0;
      transform: translate(100%);
      transition: transform 0.6s, opacity 0.1s;
      position: absolute;
      height: 100vh;
      width: 100vw;
      top: 0;
      left: 0;
      color: #a9a9a9;
      margin: 0;
      flex-direction: column;

      li {
        a {
          font-family: "Great Vibes", cursive;
          color: grey !important;
          font-size: 2.5rem;
          line-height: 3.5rem;
          text-transform: unset;
        }
        &.selected {
          list-style: inside;
        }
      }

      &.menu__opened {
        display: flex;
      }
    }

    &.negative {
      background-color: transparent;
    }
  }
`
