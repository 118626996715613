import { useState, useEffect } from "react"
import root from "../utils/root"

const SIZES = {
  XL: "XL",
  L: "L",
  M: "M",
  S: "S",
  XS: "XS",
}
const SIZES_BOUNDARIES = {
  [SIZES.XL]: 1500,
  [SIZES.L]: 1200,
  [SIZES.M]: 920,
  [SIZES.S]: 768,
  [SIZES.XS]: 576,
}

const MEDIA_QUERIES = Object.entries(SIZES_BOUNDARIES).reduce(
  (acc, [size, vh]) => {
    acc[size] = `@media (min-width: ${vh}px)`
    return acc
  },
  {}
)

const getViewportSize = () =>
  Object.entries(SIZES_BOUNDARIES).reduceRight((acc, [size, width]) => {
    if (width <= root.innerWidth) {
      return size
    }

    return acc
  }, SIZES.XS)

function useViewportSize() {
  const [viewportSize, setViewportSize] = useState(getViewportSize())

  useEffect(() => {
    const handleResize = () => {
      setViewportSize(getViewportSize())
    }
    root.addEventListener("resize", handleResize)
    return () => {
      root.removeEventListener("resize", handleResize)
    }
  })

  return viewportSize
}

export default useViewportSize

export { SIZES, SIZES_BOUNDARIES, MEDIA_QUERIES }
