import React from "react"
import css from "./hero.css"
import Img from "gatsby-image"

export default function Hero({ img, content, height = "100vh" }) {
  return (
    <section style={{ height: height }} css={css}>
      <div className={"image-container"}>
        <Img
          fluid={img.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          alt=""
        />
      </div>
      <div className={"container"}>{content}</div>
    </section>
  )
}
