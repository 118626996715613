import React, { useRef, useState } from "react"

import { Link, navigate } from "gatsby"

import css from "./header.css"
import useOffset from "../hooks/useOffset"
import root from "../utils/root"
import useViewportSize, { SIZES } from "../hooks/useViewportSize"

const routes = [
  { value: "/", label: "Inicio" },
  { value: "/boda/", label: "La Boda" },
  { value: "/rsvp/", label: "Confirma" },
  { value: "/regalos/", label: "Regalos" },
  { value: "/galeria/", label: "Galería" },
  { value: "/nuestra-gente/", label: "Nuestra Gente" },
  { value: "/nuestra-historia/", label: "Nuestra Historia" },
  { value: "/informacion/", label: "Información" },
]

const wait = ms => new Promise(resolve => setTimeout(resolve, ms))

const getCurrentPathName = () => {
  const href = root?.location?.href
  if (!href) {
    return ""
  }

  const { pathname } = new URL(href)
  return pathname
}

export default function Header({ className }) {
  const ref = useRef(null)
  const [isOpened, setIsOpened] = useState(null)
  const [currentPath, setCurrentPath] = useState(getCurrentPathName())
  const [offset] = useOffset({ speed: 1, node: ref.current })
  const viewportSize = useViewportSize()

  return (
    <header
      ref={ref}
      css={css}
      className={[
        className,
        offset > 0 ? "negative" : "",
        isOpened === true ? "opened" : "",
        isOpened === false ? "closed" : "",
      ].join(" ")}
    >
      <span
        onClick={() => {
          setIsOpened(!isOpened)
        }}
      >
        {isOpened ? "Cerrar" : "Menú"}
      </span>
      <div className={"menu__background"} />
      <ul>
        {routes.map(({ label, value }) => (
          <li className={value === currentPath ? "selected" : ""}>
            <Link
              onClick={async ev => {
                setCurrentPath(value)
                if (viewportSize === SIZES.XS) {
                  ev.preventDefault()
                  setIsOpened(false)
                  await wait(500)
                  navigate(value)
                }
              }}
              to={value}
            >
              {label}
            </Link>
          </li>
        ))}
      </ul>
    </header>
  )
}
