import React from "react"

export default function Separator({ style }) {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        ...style,
      }}
    >
      <span
        style={{
          height: "2px",
          width: "30px",
          backgroundColor: "#c9c9c9",
        }}
      ></span>
    </div>
  )
}
