import { css } from "@emotion/core"

export default css`
  background-color: #585858;
  display: flex;
  color: #fff;
  width: 100%;
  padding: 1.5rem 0;
  font-family: "Cinzel";
  font-size: 1.4rem;
  justify-content: center;

  span {
    line-height: 50px;
    margin: 0 0.5rem;

    &.icon-heart {
      color: #d7443e;
      font-size: 2.3rem;
    }
  }

  @media (max-width: 720px) {
    flex-direction: column;
    text-align: center;
    padding: 1rem 0;

    span {
      font-size: 1.2rem;
      line-height: 2rem;
      &.icon-heart {
        font-size: 2rem;
      }
    }
  }
`
