import React from "react"

import css from "./section.css"

export default function Section({ className, children, style }) {
  return (
    <section className={className} style={style} css={css}>
      {children}
    </section>
  )
}
