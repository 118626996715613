import { css } from "@emotion/core"

export default css`
  .hideHeader {
    &:not(.negative) {
      transform: translateY(-90px);
    }
  }

  .header {
    transition: transform 0.3s;
  }

  .container {
    width: 100%;
  }
`
