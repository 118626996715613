import React from "react"
import Helmet from "react-helmet"
import Hero from "../components/hero"
import { withPrefix } from "gatsby"

import css from "./layout.css"

import Header from "./header"
import Footer from "./footer"

export default function Layout({
  children,
  hideHeader,
  heroContent,
  heroHeight,
  heroImg,
}) {
  return (
    <>
      <Helmet>
        <link
          href={withPrefix("css/icons.css")}
          rel="stylesheet"
          type="text/css"
        />
        <link
          href={withPrefix("css/general.css")}
          rel="stylesheet"
          type="text/css"
        />
      </Helmet>
      <div css={css}>
        <Header
          className={["header", hideHeader ? "hideHeader" : ""].join(" ")}
        ></Header>
        <Hero content={heroContent} height={heroHeight} img={heroImg}></Hero>
        <div className={"container"}>
          {[].concat(children).map(child => child)}
        </div>
        <Footer />
      </div>
    </>
  )
}
