import { css } from "@emotion/core"

export default css`
  width: 100%;
  overflow: hidden;
  position: relative;
  transition: max-height 0.5s;

  .gatsby-image-wrapper {
    max-width: unset !important;
    max-height: unset !important;
    height: 100%;

    img {
      object-position: center 30% !important;
    }
  }

  .image-container {
    height: 100%;
  }

  .container {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }

  @media (max-width: 720px) {
    .gatsby-image-wrapper {
      position: unset !important;
    }
  }
`
